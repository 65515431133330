<template>
  <VChart class="chart" :option="option" />
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent])

export default {
  name: 'PieChart',
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: 'ligth'
  },

  props: ['pagamentoData'],
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : R$ {c}'
        },

        legend: {
          left: 'center'
        },

        series: [
          {
            name: 'Tipo de Pagamento',
            type: 'pie',
            radius: [150, 80],
            roseType: 'area',
            avoidLabelOverlap: false,

            itemStyle: {
              borderRadius: 8
            },

            data: [],

            center: ['60%', '63%'],

            label: {
              show: false,
              position: 'center',
              formatter: function (valorPagamento) {
                return 'R$ ' + valorPagamento.value
              }
            },

            emphasis: {
              itemStyle: {
                shadowBlur: 20,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },

              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              },
              labelLine: {
                show: false
              }
            }
          }
        ]
      }
    }
  },

  watch: {
    pagamentoData() {
      if (this.pagamentoData.length > 0) {
        this.option.series[0].data = this.pagamentoData
        this.option.legend.data = this.pagamentoData.map((pagamento) => pagamento.name)
      } else {
        this.option.series[0].data = [{ name: 'Nenhum pagamento encontrado', value: 0 }]
        this.option.legend.data = ['Nenhum pagamento encontrado']
      }
    }
  }
}
</script>

<style scoped>
.chart {
  height: 430px;
}
</style>
