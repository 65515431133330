import request from '@/common/request'

class OrganizacaoService {
  buscarPeloId(id) {
    return request.get(`/organizacoes/${id}`)
  }

  buscarPeloToken() {
    return request.get(`/organizacoes`)
  }

  atualizar(id, data) {
    return request.put(`/organizacoes/${id}`, data)
  }

  informarIntegracao(organizacaoId) {
    return request.get(`/organizacoes/${organizacaoId}/integracoes`)
  }

  buscaAdquirentePadrao() {
    return request.get(`/organizacoes/adquirente/padrao`)
  }
}

export default new OrganizacaoService()
