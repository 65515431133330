export function buscaDescricaoPagamento(codigoTipoPagamento, adquirente) {
  if (adquirente === 'PAYPAL') {
    switch (codigoTipoPagamento) {
      case 'T0001':
        return 'PAGAMENTO MASSPAY'

      case 'T0002':
        return 'PAGAMENTO DE ASSINATURA. O PAGAMENTO FOI ENVIADO OU O PAGAMENTO RECEBIDO'

      case 'T0003':
        return 'PAGAMENTO PRÉ-APROVADO (API BILLUSER). ENVIADO OU RECEBIDO'

      case 'T0004':
        return 'PAGAMENTO DE LEILÃO DO EBAY'

      case 'T0005':
        return 'PAGAMENTO DIRETO API'

      case 'T0006':
        return 'PAGAMENTO DIRETO'

      case 'T0007':
        return 'PAGAMENTO PADRÃO WEBSITE'

      case 'T0008':
        return 'PAGAMENTO POSTAGEM TRANSPORTADORA'

      case 'T0009':
        return 'PAGAMENTO VALE-PRESENTE'

      case 'T0010':
        return 'PAGAMENTO LEILÃO DE TERCEIROS'

      case 'T0011':
        return 'PAGAMENTO MÓVEL, FEITO ATRAVÉS DO CELULAR'

      case 'T0012':
        return 'PAGAMENTO DE TERMINAL VIRTUAL'

      case 'T0014':
        return 'PAGAMENTO DE DESCONTOS'

      case 'T0114':
        return 'TAXA DISPUTA'

      case 'T0400':
        return 'RETIRADA GERAL DA CONTA PAYPAL'

      case 'T1106':
        return 'ESTORNO DE PAGAMENTO, INICIADO PELO PAYPAL'

      case 'T1107':
        return 'REEMBOLSO PAGAMENTO, INICIADO PELO COMERCIANTE'

      case 'T1108':
        return 'REVERSÃO DE TAXAS'

      case 'T1109':
        return 'REEMBOLSO DA TAXA'

      case 'T1110':
        return 'RETENÇÃO PARA INVESTIGAÇÃO DE DISPUTA'

      case 'T1111':
        return 'CANCELAMENTO DA RETENÇÃO PARA RESOLUÇÃO DE DISPUTAS'

      case 'T1117':
        return 'ESTORNO DE DESCONTO OU REEMBOLSO'

      case 'T1201':
        return 'ESTORNO'

      case 'T1202':
        return 'ESTORNO REVERTIDO'

      case 'T1900':
        return 'AJUSTE GERAL SEM EVENTO RELACIONADO A NEGÓCIOS'

      default:
        return ''
    }
  } else if (adquirente === 'PAGSEGURO') {
    switch (codigoTipoPagamento) {
      case 'PGS_001':
        return 'VENDA OU PAGAMENTO'

      case 'PGS_002':
        return 'AJUSTE A CRÉDITO'

      case 'PGS_003':
        return 'AJUSTE A DÉBITO'

      case 'PGS_004':
        return 'OPERAÇÃO CONTA PAGSEGURO'

      case 'PGS_005':
        return 'CHARGEBACK'

      case 'PGS_006':
        return 'CANCELAMENTO'

      case 'PGS_007':
        return 'SALDO INICIAL'

      case 'PGS_008':
        return 'SALDO FINAL'

      case 'PGS_009':
        return 'ABERTURA DISPUTA'

      case 'PGS_010':
        return 'ENCERRAMENTO DISPUTA'

      case 'PGS_011':
        return 'ABERTURA PRÉ-CHARGEBACK'

      case 'PGS_012':
        return 'ENCERRAMENTO PRÉ CHARGEBACK'

      default:
        return ''
    }
  } else if (adquirente === 'GETNET') {
    switch (codigoTipoPagamento) {
      case 'T0006':
        return 'PAGAMENTO DIRETO'
    }
  }
}

export function buscaTipoPagamentoFiltro(adquirente) {
  if (adquirente === 'PAYPAL') {
    const filtro = [
      { text: 'T0006 - PAGAMENTO DIRETO', value: 'T0006' },
      { text: 'T0114 - TAXA DISPUTA', value: 'T0114' },
      { text: 'T0400 - RETIRADA GERAL DA CONTA PAYPAL', value: 'T0400' },
      { text: 'T1106 - ESTORNO DE PAGAMENTO, INICIADO PELO PAYPAL', value: 'T1106' },
      { text: 'T1107 - REEMBOLSO PAGAMENTO, INICIADO PELO COMERCIANTE', value: 'T1107' },
      { text: 'T1110 - RETENÇÃO PARA INVESTIGAÇÃO DE DISPUTA', value: 'T1110' },
      { text: 'T1111 - CANCELAMENTO DA RETENÇÃO PARA RESOLUÇÃO DE DISPUTAS', value: 'T1111' },
      { text: 'T1201 - ESTORNO', value: 'T1201' },
      { text: 'T1202 - ESTORNO REVERTIDO', value: 'T1202' },
      { text: 'T1900 - AJUSTE GERAL SEM EVENTO RELACIONADO A NEGÓCIOS', value: 'T1900' }
    ]

    return filtro
  } else if (adquirente === 'PAGSEGURO') {
    const filtro = [
      { text: 'PGS_001 - VENDA OU PAGAMENTO', value: 'PGS_001' },
      { text: 'PGS_002 - AJUSTE A CRÉDITO', value: 'PGS_002' },
      { text: 'PGS_003 - AJUSTE A DÉBITO', value: 'PGS_003' },
      { text: 'PGS_004 - OPERAÇÃO CONTA PAGSEGURO', value: 'PGS_004' },
      { text: 'PGS_005 - CHARGEBACK', value: 'PGS_005' },
      { text: 'PGS_006 - CANCELAMENTO', value: 'PGS_006' },
      { text: 'PGS_007 - SALDO INICIAL', value: 'PGS_007' },
      { text: 'PGS_008 - SALDO FINAL', value: 'PGS_008' },
      { text: 'PGS_009 - ABERTURA DISPUTA', value: 'PGS_009' },
      { text: 'PGS_010 - ENCERRAMENTO DISPUTA', value: 'PGS_010' },
      { text: 'PGS_011 - ABERTURA PRÉ-CHARGEBACK', value: 'PGS_011' },
      { text: 'PGS_012 - ENCERRAMENTO PRÉ CHARGEBACK', value: 'PGS_012' }
    ]
    return filtro
  } else if (adquirente === 'GETNET') {
    const filtro = [
      { text: 'GET_001 - PAGAMENTO DIRETO', value: 'GET_001' },
      { text: 'GET_002 - ESTORNO', value: 'GET_002' }
    ]
    return filtro
  }
}
