<template>
  <v-container fluid>
    <v-row>
      <v-col sm="12" md="5">
        <v-card elevation="10">
          <v-card-title> PAGAMENTOS </v-card-title>

          <v-card-text>
            <v-row>
              <v-col class="d-flex" align="center">
                <v-select
                  v-model="filtroAdquirenteDash"
                  :items="itensAdquirente"
                  label="Adquirente"
                  @change="atualizarValores()"
                />
              </v-col>
            </v-row>

            <v-row align="center" justify="center" class="py-4">
              <v-btn-toggle v-model="indexDia" color="primary">
                <v-btn color="white" @click="diaAtual = 30"> 30 Dias </v-btn>
                <v-btn color="white" @click="diaAtual = 60"> 60 Dias </v-btn>
                <v-btn color="white" @click="diaAtual = 90"> 90 Dias </v-btn>
              </v-btn-toggle>
            </v-row>

            <v-row v-if="buscandoValores" align="center" justify="center">
              <v-col cols="2" class="d-flex justify-center">
                <v-progress-circular indeterminate color="primary" />
              </v-col>
            </v-row>

            <v-row v-if="!buscandoValores" class="mt-5">
              <v-col cols="6" style="padding-bottom: 0">
                <b>Valor Total:</b>
                {{
                  pagamento.valorPagamento.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }}
              </v-col>
              <v-col cols="6" style="padding-bottom: 0">
                <b>Valor Retirada:</b>
                {{
                  pagamento.valorRetirada.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }}
              </v-col>
              <v-col cols="6" style="padding-bottom: 0">
                <b>Valor Taxa:</b>
                {{
                  pagamento.valorTaxa.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }}
              </v-col>
              <v-col cols="6">
                <b>Valor Pagamento Sem Taxa:</b>
                {{
                  pagamento.valorPagamentoSemTaxa.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col sm="6" md="7">
        <v-card elevation="7">
          <v-card-title> PAGAMENTOS POR DIA </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="filtroAdquirentePie"
                  :items="itensAdquirente"
                  label="Adquirente"
                />

                <v-menu
                  ref="menuData"
                  v-model="menuData"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="dataInicialFormatada"
                      label="Data"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="dataPagamentosPorDia"
                    color="primary"
                    no-title
                    @input="menuData = false"
                  />
                </v-menu>

                <v-btn color="primary" outlined type="submit" @click="buscarValoresPeloTipo()">
                  <v-icon left> mdi-magnify </v-icon>
                  Pesquisar
                </v-btn>
              </v-col>

              <v-col cols="8">
                <PagamentoDiaPieChart :pagamento-data="dadosPieChart" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <SnackbarMessage
      :show-snack.sync="snackbar.showSnackParam"
      :snack-text="snackbar.snackTextParam"
      :snack-type="snackbar.snackTypeParam"
    />
  </v-container>
</template>

<script>
import PagamentoService from '@/services/pagamento/pagamento'
import PagamentoDiaPieChart from '@/components/dashboard/PagamentoDiaPieChart'
import { formatDate } from '@/shareds/formatacoes'
import Snackbar from '@/models/ui/snackbar'
import SnackbarMessage from '@/components/ui/SnackbarMessage'
import { buscaDescricaoPagamento } from '@/shareds/tipoPagamento'
import OrganizacaoService from '@/services/organizacao/organizacao'

export default {
  name: 'Home',

  components: {
    PagamentoDiaPieChart,
    SnackbarMessage
  },

  data() {
    return {
      indexDia: 0,
      pagamento: {
        valorPagamento: 0,
        valorRetirada: 0,
        valorTaxa: 0,
        valorPagamentoSemTaxa: 0
      },
      pagamentoInfo: [],
      diaAtual: 30,
      buscandoValores: false,
      datacollection: null,
      menuData: false,
      dataPagamentosPorDia: '',
      dataInicialFormatada: '',
      dadosPieChart: [],
      snackbar: new Snackbar(),
      filtroAdquirenteDash: [],
      filtroAdquirentePie: [],

      itensAdquirente: [
        { text: 'PayPal', value: 'PAYPAL' },
        { text: 'PagSeguro', value: 'PAGSEGURO' },
        { text: 'GetNet', value: 'GETNET' },
        { text: 'Rede', value: 'REDE' }
      ]
    }
  },

  watch: {
    diaAtual() {
      this.atualizarValores()
    },

    dataPagamentosPorDia() {
      this.dataInicialFormatada = formatDate(this.dataPagamentosPorDia)
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      this.buscandoValores = true
      this.dataPagamentosPorDia = new Date().toISOString().slice(0, 10)

      await this.buscaAdquirentePadraoPelaOrganizacao()

      PagamentoService.buscarValoresTotaisDash()
        .then((response) => {
          this.pagamentoInfo = response
          this.atualizarValores()
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os pagamentos.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
        .finally(() => (this.buscandoValores = false))

      PagamentoService.buscarValoresPeloTipo(this.dataPagamentosPorDia, this.filtroAdquirentePie)
        .then((response) => {
          this.dadosPieChart = response.map((item) => ({
            name: buscaDescricaoPagamento(item.codigoEventoTransacao, this.filtroAdquirentePie),
            value: Math.abs(item.valorPagamento)
          }))
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os pagamentos.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
    },

    atualizarValores() {
      const pagamentoAdquirente = this.pagamentoInfo.find((item) => {
        return item.adquirente === this.filtroAdquirenteDash
      })

      this.pagamento = pagamentoAdquirente.pagamentoValoresDetalhados.find(
        (item) => item.dias === this.diaAtual
      )
    },

    buscarValoresPeloTipo() {
      PagamentoService.buscarValoresPeloTipo(this.dataPagamentosPorDia, this.filtroAdquirentePie)
        .then((response) => {
          this.dadosPieChart = response.map((item) => ({
            name: buscaDescricaoPagamento(item.codigoEventoTransacao, item.adquirente),
            value: Math.abs(item.valorPagamento)
          }))
        })
        .catch((erro) => {
          this.snackbar.snackTextParam = `${erro.response.status} | ${
            erro.response.data.message === undefined
              ? erro.message
              : erro.response.data.message
              ? erro.response.data.message
              : 'Ocorreu um erro ao buscar os pagamentos.'
          }`
          this.snackbar.snackTypeParam = 'error'
          this.snackbar.showSnackParam = true
        })
    },

    async buscaAdquirentePadraoPelaOrganizacao() {
      try {
        const filtroAdquirentePadrao = await OrganizacaoService.buscaAdquirentePadrao()
        this.filtroAdquirenteDash = filtroAdquirentePadrao
        this.filtroAdquirentePie = filtroAdquirentePadrao
      } catch (erro) {
        this.snackbar.snackTextParam = `${erro.response.status} | ${
          erro.response.data.message === undefined
            ? erro.message
            : erro.response.data.message
            ? erro.response.data.message
            : 'Ocorreu um erro ao buscar o adquirente.'
        }`
        this.snackbar.snackTypeParam = 'error'
        this.snackbar.showSnackParam = true
      }
    }
  }
}
</script>
