import request from '@/common/request'

class PagamentoService {
  buscarTudo(params) {
    return request.get('/pagamentos', {
      params
    })
  }

  buscarPagamentosConciliados(params) {
    return request.get('/pagamentos/conciliados', {
      params
    })
  }

  buscarTitulosConciliados(pagamentoId) {
    return request.get(`/pagamentos/${pagamentoId}/titulos`)
  }

  deletarConciliacao(pagamentoConciliadoId, tituloId) {
    return request.delete(`/pagamentos/conciliados/${pagamentoConciliadoId}/titulos/${tituloId}`)
  }

  deletarPagamentoConciliado(pagamentoConciliadoId) {
    return request.delete(`/pagamentos/conciliados/${pagamentoConciliadoId}/titulos`)
  }

  confirmarPagamentosConciliados(pagamentoConciliadoIds) {
    return request.patch('/pagamentos/conciliados/status', pagamentoConciliadoIds)
  }

  confirmarPagamentoConciliado(pagamentoConciliadoId) {
    return request.patch(`/pagamentos/conciliados/${pagamentoConciliadoId}/status`)
  }

  buscarValoresTotaisDash(adquirente) {
    return request.get('/dashboard/pagamentos', {
      params: { adquirente }
    })
  }

  buscarValoresPeloTipo(dataPagamento, adquirente) {
    return request.get('/dashboard/pagamentos/tipos', {
      params: { dataPagamento, adquirente }
    })
  }

  buscarValoresTotais(queryParams) {
    return request.get('/pagamentos/valoresTotais', {
      params: {
        ...queryParams
      }
    })
  }

  buscarValoresTotaisConciliados(queryParams) {
    return request.get('/pagamentos/conciliados/valoresTotais', {
      params: {
        ...queryParams
      }
    })
  }

  deletarPagamentoConciliadoPendentes(queryParams) {
    return request.delete('/pagamentos/conciliados/pendentes', { params: { ...queryParams } })
  }

  confirmarPagamentosConciliadosPendentes(queryParams) {
    return request.patch('/pagamentos/conciliados/pendentes', null, { params: { ...queryParams } })
  }

  export(params) {
    return request.get(
      'pagamentos/pagamentoExport?organizacaoId=c7b4f653-beca-4f94-bde2-177ece6f9c2f',
      { params }
    )
  }
  exportarConciliacao(params) {
    return request.get(
      'pagamentos/exportarConciliacao?organizacaoId=c7b4f653-beca-4f94-bde2-177ece6f9c2f',
      { params }
    )
  }

  exportPagamentoSemConciliacao(params) {
    return request.get(
      'pagamentos/pagamentoSemConciliacaoExport?organizacaoId=c7b4f653-beca-4f94-bde2-177ece6f9c2f',
      { params }
    )
  }
}

export default new PagamentoService()
